/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import WorkshopListing from "@modules/WorkshopListing"
import schoolHeader from "@static/images/book-now/bookNowHeader.jpg"
import schoolworkshopHeader from "@static/images/workshops/schoolworkshopHeader.jpg"

const pageCopy = {
  header: {
    block1: {
      title: "INCLUSIVE CLASSROOMS",
      body:
        "Whether you’re responding to a school incident, supporting a student or celebrating diversity, Minus18 is here to guide your staff and students through LGBTQIA+ knowledge and inclusion.",
    },
    block2Image: schoolworkshopHeader,
  },
  iconThreeUpTextBlocks: [
    "Developed in line with DET and Respectful Relationships curriculums.",
    "Light-hearted, engaging, and interactive approach.",
    "Highly experienced presenters, 100s of schools engaged.",
  ],
}

export default props => {
  const data = useStaticQuery(
    graphql`
      query {
        workshops: allMarkdownRemark(
          filter: {
            fileAbsolutePath: { glob: "**/posts/workshop/*.md" }
          }
          sort: { order: ASC, fields: frontmatter___ordering }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                image
                description
                outcomes
                duration
                audience
                capacity
                cost
                forTeachers
                workshopType
              }
            }
          }
        }
        pageData: markdownRemark(
          fileAbsolutePath: { glob: "**/pages/training-and-speakers/index.md" }
        ) {
          frontmatter {
            header {
                ...Header
            }
            threeColumnTextBlock {
              heading
              columns {
                image
                heading
                blockHeading
                text
                ctaButton {
                  label
                  link
                }
              }
            }
            imageGallery{
              image1
              image2
              image3
              image4
              image5
              image6
            }
            threeUpBlock {
                heading
                columns {
                    text
                }
            }
            carousel {
                heading
                image
                text
                footnote
                ctaButton {
                    label
                    link
                }
            }
            carouselTwo {
                heading
                image
                text
                footnote
                ctaButton {
                    label
                    link
                }
            }
            accordion {
              ...Accordion
            }
            quickLinks {
              heading
              links {
                image
                heading
                text
                link
              } 
            }
            imageGallery{
              image1
              image2
              image3
              image4
              image5
              image6
            }
            ctaBlock {
              heading
              subheading
              text
              ctaButton {
                label
                link
              }
            }
            ogTitle
            ogimage
            ogdescription
          }
        }
      }
    `
  )

  return (
    <WorkshopListing
      pageTitle="LGBTQIA+ Training and Guest Speakers"
      pageImage={schoolHeader}
      data={data}
      pageCopy={pageCopy}
      type="schools"
      pathname={props.location.pathname}
    />
  )
}
