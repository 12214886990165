/** @jsx jsx */
import { jsx } from "theme-ui"
import Carousel from "@components/Carousel"
import Testimonial from "@components/Testimonial"
import PropTypes from "prop-types"

/**
 * @todo mobile/tablet arrow position
 */
const TestimonialCarousel = ({ testimonials }) => (
  <Carousel
    containerStyle={{
      width: "80%",
      mx: "auto",
    }}
    settings={{
      dots: false,
    }}
  >
    {testimonials.map(({ text, ...testimonial }, index) => (
      <div key={index} sx={{ px: 3 }}>
        <Testimonial body={text} {...testimonial} />
      </div>
    ))}
  </Carousel>
)

export default TestimonialCarousel

TestimonialCarousel.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.string,
      footnote: PropTypes.string,
    })
  ),
}
