/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import theme, { rem } from "@src/theme"

export const Block = ({ styles, children, fullFlex = null, backgroundImage = undefined }) => {
  return (
    <BlockOuter
      bg={backgroundImage ? 'none' : styles.bg}
      color={styles.color}
      fullFlex={fullFlex}
      height={styles.height}
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {children}
    </BlockOuter>
  )
}

export const ImageBlock = ({ image }) => (
  <div>
    <div
      sx={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: [rem(420), null, "100%"],
        minHeight: [rem(360), null, rem(550)],
      }}
    ></div>
  </div>
)

const TwoColumnSection = ({ col1, col2 }) => (
  <Outer
    sx={{
      display: "flex",
      flexDirection: ["column", null, "row", null],
    }}
  >
    {col1}
    {col2}
  </Outer>
)

const Outer = styled.section`
  > :first-child {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  > :nth-child(2) {
    flex: 1.8;
    display: flex;
  }
`
const BlockOuter = styled.div`
  flex-grow: 1;
  padding: ${0} 5vw ${0} 5vw;
  background: ${p => theme.colors[p.bg]};
  color: ${p => theme.colors[p.color]};
  width: 100%;
  height: ${p => p.height};
  ${p =>
    p.fullFlex &&
    `
  flex: 1;
  `}
`

export default TwoColumnSection
