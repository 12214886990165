/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import React from "react"
import { Grid } from "@theme-ui/components"
import PropTypes from "prop-types"
import Banner from "@components/Banner"
import { rem } from "@src/theme"
import { Themed } from '@theme-ui/mdx'
import Button from "@components/Button"
import ButtonLink from "@components/ButtonLink"
import Spacers from "@components/Spacers"


const Testimonial = ({ heading, body, image, footnote = "", ctaButton }) => (
  <Grid gap={5} columns={[1, null, null, 2]}>
    <div
      sx={{
        height: [rem(250), rem(350), null, rem(509)],
        width: "100%",
      }}
    >
      <img src={image} className="object-fit" />
    </div>

    <div
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div>
        {heading && (
          <>
            <Banner title={heading} small />
          </>
        )}
        
        <Themed.h4
          as="p"
          sx={{
            mt: rem(48),
            mb: 0,
            lineHeight: 1.1,
          }}
        >
          {body}
        </Themed.h4>
        {footnote && (
          <p
            sx={{
              mb: 0,
            }}
          >
            {footnote}
          </p>
        )}
        {ctaButton && (
          <>
            <Spacers.Medium />
            <ButtonLink to={ctaButton.link}>
              {ctaButton.label}
            </ButtonLink>
          </>
        )}
      </div>
    </div>
  </Grid>
)

export default Testimonial

Testimonial.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
  image: PropTypes.string,
}
