/** @jsx jsx */
import { jsx } from "theme-ui"
import { Grid, Image } from "@theme-ui/components"
import PropTypes from "prop-types"
import { rem } from "@src/theme"
import BannerBlock from "@components/BannerBlock"
import { Themed } from '@theme-ui/mdx'
import ButtonLink from "@components/ButtonLink"
import Banner from "@components/Banner"
import Spacing from "@components/Spacers"

const CalloutTextBlocks = ({ blocks, heading }) => {
  return heading ? (
    <BannerBlock title={heading} noMaxWidth>
      { blocks[0] && blocks[0].blockHeading && (<Spacing.Small />)}
      <Grid columns={[1, 1, Math.min(blocks.length, 3)]} gap={2}>
        {blocks.slice(0, 3).map(({ heading, text, blockHeading, ctaButton, image }) => (
          <div
            sx={{
              mx: "auto",
              textAlign: "center",
              px: [0, 2, null, 0],
              maxWidth: [rem(325), rem(400), "auto"],
              mb: [rem(70), null, 0],
            }}
            key={heading}
          >
            {image && (
              <Image
                style={{
                  marginBottom: rem(30),
                  objectFit: 'cover',
                  width: "100%",
                  maxHeight: "220px",
                }}
                src={image}
              />
            )}
            {blockHeading && (<Banner large style={{ marginBottom: rem(50) }} title={blockHeading} />)}
            <Themed.h3 sx={{ mt: 0, mb: rem(30) }}>{heading}</Themed.h3>
            <p
              sx={{
                mb: 0,
              }}
            >
              {text}
            </p>
            {ctaButton && ctaButton.label &&
              <ButtonLink
                styles={{ marginTop: rem(30) }}
                to={ctaButton.link}
              >
                {ctaButton.label}
              </ButtonLink>
            }
          </div>
        ))}
      </Grid>
    </BannerBlock>
  ) : (
    <Grid columns={[1, 1, Math.min(blocks.length, 3)]} gap={2}>
      {blocks.slice(0, 3).map(({ heading, text, image, blockHeading, ctaButton }) => (
        <div
          sx={{
            mx: "auto",
            textAlign: "center",
            px: [0, 2, null, 0],
            maxWidth: [rem(325), rem(400), "auto"],
            mb: [rem(70), null, 0],
          }}
          key={heading}
        >
          {image && (
            <Image
              style={{
                marginBottom: rem(30),
                objectFit: 'cover',
                width: "100%",
                maxHeight: "220px",
              }}
              src={image}
            />
          )}
          {blockHeading && (<Banner large style={{ marginBottom: rem(50) }} title={blockHeading} />)}
          <Themed.h3 sx={{ mt: 0, mb: rem(30) }}>{heading}</Themed.h3>
          <p
            sx={{
              mb: 0,
            }}
          >
            {text}
          </p>
          {ctaButton && ctaButton.label &&
            <ButtonLink
              styles={{ marginTop: rem(30) }}
              to={ctaButton.link}
            >
              {ctaButton.label}
            </ButtonLink>
          }
        </div>
      ))}
    </Grid>
  )
}

export default CalloutTextBlocks

CalloutTextBlocks.propTypes = {
  heading: PropTypes.string,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      heading: PropTypes.string,
    })
  ),
}
