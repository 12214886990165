/** @jsx jsx */
import { jsx } from "theme-ui"
import Link from "@components/Link"
import ButtonLink from "@components/ButtonLink"
import PropTypes from "prop-types"
import { BodyLarge } from "@components/primitives/Text"
import Button from "@components/Button"
import theme, { rem } from "@src/theme"
import MailchimpDownloadButton from "@modules/MailchimpDownloadButton"
import Spacing from "@components/Spacers"
import { Themed } from '@theme-ui/mdx'

import Banner from "@components/Banner"

const CTAHeadingBlock = ({
  heading,
  body,
  buttonLabel,
  buttonLink,
  buttonBg = "darkPurple",
  buttonColor = "white",
  onButtonClick,
  linkLabel,
  linkTo,
  linkExternal,
  smallHeading,
  widthLimit,
  downloadResource,
  downloadLabel,
  downloadTag,
  textBorder,
  centerText = false
}) => (
  <div
    sx={{
      maxWidth: widthLimit ? rem(750) : "initial",
      textAlign: centerText ? "center" : "left",
    }}
  >
    <Spacing.Medium />
    {!smallHeading ? (
      <Themed.h1
        sx={{
          m: 0,
          transform: textBorder ? 'rotate(-0.75deg)' : ''
        }}
      >
        <span
          sx={textBorder && {
            whiteSpace: "pre-wrap",
            backgroundColor: theme.colors.darkPurple,
            borderLeft: `0.1em solid ${theme.colors.darkPurple}`,
            borderRight: `0.2em solid ${theme.colors.darkPurple}`,
            pt: rem(8),
            color: 'white',
          }}
        >
          {heading}
        </span>
      </Themed.h1>
    ) : heading ? (
      <Themed.h3
        sx={{
          m: 0,
        }}
      >
        {heading}
      </Themed.h3>
    ) : null}
    {body && <Spacing.XSmall />}
    {body && (
      <BodyLarge
        style={{
          my: 0,
        }}
      >
        {body}
      </BodyLarge>
    )}

    {buttonLabel || linkLabel || downloadResource ? <Spacing.Small /> : null}

    {downloadResource ? (
      <MailchimpDownloadButton
        label={downloadLabel}
        mailchimpTag={downloadTag}
      />
    ) : (buttonLabel && onButtonClick) ||
      (buttonLabel && buttonLink) ||
      (linkTo && linkLabel) ? (
      <div
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {buttonLabel && buttonLink && (
          <ButtonLink
            to={buttonLink}
            className="unstyled-link"
            bg={buttonBg}
            color={buttonColor}
          >
            {buttonLabel}
          </ButtonLink>
        )}

        {buttonLabel && onButtonClick && (
          <Button
            onClick={onButtonClick}
            bg={buttonBg}
            color={buttonColor}
            sx={{ mr: 4 }}
          >
            {buttonLabel}
          </Button>
        )}

        {linkTo && linkLabel && (
          <Link
            to={linkTo}
            link={linkTo}
            style={{
              ml: buttonLabel ? 3 : 0,
            }}
            external={linkExternal}
          >
            {linkLabel}
          </Link>
        )}
      </div>
    ) : null}
    <Spacing.Medium />
  </div>
)

CTAHeadingBlock.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonLink: PropTypes.string,
  onButtonClick: PropTypes.func,
  linkLabel: PropTypes.string,
  linkTo: PropTypes.string,
}

export default CTAHeadingBlock
