/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { rem } from "@src/theme"
import Download from "@components/assets/svg/download.svg"

const MailchimpDownloadButton = ({
  label = "Download",
  openMailchimpModal,
  setMailchimpTag,
  mailchimpTag,
}) => (
  <button
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "inherit",
      cursor: "pointer",
    }}
    onClick={() => {
      openMailchimpModal()
      setMailchimpTag(mailchimpTag)
    }}
  >
    <span
      sx={{
        display: "block",
        variant: "typography.link",
        textDecoration: "underline",
        color: "inherit",
        "&.active": {
          color: "primary",
        },
      }}
    >
      {label}
    </span>

    <div
      sx={{
        width: rem(13),
        transform: "translate(8px, -2px)",
      }}
    >
      <Download width="100%" />
    </div>
  </button>
)

MailchimpDownloadButton.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
}

export default connect(null, dispatch => ({
  openMailchimpModal: () => dispatch.mailchimpModal.toggleModal(true),
  setMailchimpTag: tag => dispatch.mailchimpModal.setMailchimpTag(tag),
}))(MailchimpDownloadButton)
