import React from 'react'
import { Image } from '@theme-ui/components'
import * as Styles from "./styles.module.css"

export default function ImageGallery ({ image1, image2, image3, image4, image5, image6 }) {
    return (
        <div className={Styles.imageGalleryWrapper}>
            {image1 && <Image
                className={Styles.image1}
                src={image1}
            />}
            {image2 && <Image
                className={Styles.image2}
                src={image2}
            />}
            {image3 && <Image
                className={Styles.image3}
                src={image3}
            />}
            {image4 && <Image
                className={Styles.image4}
                src={image4}
            />}
            {image5 && <Image
                className={Styles.image5}
                src={image5}
            />}
            {image6 && <Image
                className={Styles.image6}
                src={image6}
            />}
        </div>
    )
}
