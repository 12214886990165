/** @jsx jsx */
import { jsx } from "theme-ui"
import BannerBlock from '@components/BannerBlock'
import Container from '@components/Container'
import Section from '@components/Section'
import theme, { rem } from '@src/theme'
import { Grid } from '@theme-ui/components'
import { navigate } from 'gatsby'
import styled from 'styled-components'



export default function QuickLinks ({ heading, quickLinks }) {
    if (!quickLinks || quickLinks.length === 0) return null
    return (
        <Section
            sx={{
                bg: "pink",
            }}
        >
            {heading && <BannerBlock title={heading} noMt />}

            <Container>
                <Grid gap={rem(32)} columns={[1, 2, 3, quickLinks.length]}>
                    {quickLinks.map(item => (
                        <QuickLinkCard
                            key={item.heading}
                            {...item}
                        />
                    ))}
                </Grid>
            </Container>
        </Section>
    )
}

const Outer = styled.div`
    cursor: pointer;
    position: relative;
    z-index: 1;
  
    &:hover,
    &:active,
    &:focus {
      background-color: ${theme.colors.lightGrey};
      transition: background-color 300ms ease, color 300ms ease;
      .pill {
        color: ${theme.colors.white};
        background-color: ${theme.colors.darkPurple};
        border-color: ${theme.colors.white};
        transition: all 300ms ease;
      }
    }
  `

const QuickLinkCard = ({ image, heading, text, link }) => {
    const onCardClick = () => navigate(link)

    const handleCardKeyDown = e => {
        if (e.key === "Enter" || e.keyCode === 13) {
            onCardClick()
        }
    }
    return (
        <Outer
            tabIndex="0"
            onClick={onCardClick}
            onKeyDown={handleCardKeyDown}
            sx={{
                display: "block",
                textDecoration: "none",
                height: "100%",
                bg: "white",
            }}
            role="button"
        >
            <figure
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    margin: 0,
                    color: "currentColor",
                    height: "100%",
                    position: "relative",
                    zIndex: 1,
                }}
            >
                <img
                    src={image}
                    alt=""
                    sx={{
                        height: rem(200),
                        objectFit: "cover",
                        width: "100%",
                        zIndex: -2,
                    }}
                />
                <div
                    sx={{
                        p: rem(30),
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                    }}
                >
                    <div>
                        <figcaption
                            sx={{
                                variant: "typography.heading",
                                fontSize: [rem(22)],
                                fontWeight: 900,
                                lineHeight: 1.4,
                                color: "currentColor",
                            }}
                        >
                            {heading}
                        </figcaption>
                        <p
                            sx={{
                                fontSize: rem(18),
                                color: "currentColor",
                            }}
                        >
                            {text}
                        </p>
                    </div>
                </div>
            </figure>
        </Outer>
    )
}


