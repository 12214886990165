import React from 'react'
import TwoColumnSection, {
    Block as ColumnBlock,
} from "@components/TwoColumnSection"
import CTAHeadingBlock from '@components/CTAHeadingBlock'
import { navigate } from 'gatsby'



export default function CallToAction ({ heading, subheading, text, ctaButton }) {
    if (!heading) return null
    return (
        <TwoColumnSection
            col1={
                <ColumnBlock
                    styles={{
                        bg: "purple",
                        color: "pink",
                    }}
                >
                    <CTAHeadingBlock heading={heading} />
                </ColumnBlock>
            }
            col2={
                <ColumnBlock
                    styles={{
                        bg: "turquoise",
                    }}
                >
                    <CTAHeadingBlock
                        heading={subheading}
                        body={text}
                        buttonLabel={ctaButton?.label}
                        onButtonClick={() => navigate(ctaButton?.link)}
                        smallHeading
                        widthLimit
                    />
                </ColumnBlock>
            }
        />
    )
}
