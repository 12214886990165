/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import slugify from "slugify"
import Layout from "@components/Layouts"
import Section from "@components/Section"
import ListingGrid from "@components/ListingGrid"
import TwoColumnSection, {
  Block as ColumnBlock,
  ImageBlock as ColumnImageBlock,
} from "@components/TwoColumnSection"
import { navigate } from "@reach/router"
import CTAHeadingBlock from "@components/CTAHeadingBlock"
import IconThreeUp from "@components/IconThreeUp"
import AccordionWithBanner from "@components/Accordion/WithBanner"
import SEO from "@components/seo"
import Spacer from "@components/Spacers"
import TestimonialCarousel from "@components/TestimonialCarousel"
import CalloutTextBlocks from "@components/CalloutTextBlocks"
import QuickLinks from "@components/QuickLinks"
import ImageGallery from "@components/ImageGallery"
import CallToAction from "@components/CallToAction"
import Container from "@components/Container"
import Accordion from "@components/Accordion"

const WorkshopListing = ({
  pageCopy,
  data,
  type,
  pageTitle,
  pageImage,
  pathname,
}) => {
  const { iconThreeUpTextBlocks } = pageCopy
  const {
    accordion,
    ogdescription,
    header,
    carousel,
    threeUpBlock,
    threeColumnTextBlock,
    carouselTwo,
    quickLinks,
    imageGallery,
    ctaBlock,
    ogTitle,
    ogimage,
  } = data.pageData.frontmatter
  const { block1, block2Image } = header

  const isSchool = type === "schools"

  const mapWorkshop = item => ({
    title: item.node.frontmatter.title,
    description: item.node.frontmatter.description,
    link: `/workshops/${item.node.frontmatter.workshopType}/${slugify(
      item.node.frontmatter.title.toLowerCase()
    )}`,
    duration: item.node.frontmatter.duration,
    capacity: item.node.frontmatter.capacity,
    forTeachers: item.node.frontmatter.forTeachers,
    type: item.node.frontmatter.workshopType,
    image: item.node.frontmatter.image,
  })

  return (
    <Layout>
      <SEO
        title={ogTitle || pageTitle}
        image={ogimage || pageImage}
        description={ogdescription}
        pathname={pathname}
      />
      {header && header.image ? (
        <TwoColumnSection
          col1={
            <div>
              <ColumnBlock
                styles={{
                  bg: "purple",
                  color: "pink",
                }}
              >
                <CTAHeadingBlock heading={header.heading} />
              </ColumnBlock>
              <ColumnBlock
                styles={{
                  bg: "pink",
                  color: "darkPurple",
                }}
              >
                <CTAHeadingBlock
                  heading={header.subheading}
                  body={header.subtext}
                  buttonLabel={header.ctaButton?.label}
                  buttonLink={header.ctaButton?.link}
                  smallHeading
                />
              </ColumnBlock>
            </div>
          }
          col2={<ColumnImageBlock image={header.image} />}
        />
      ) : (
        <TwoColumnSection
          col1={
            <ColumnBlock
              styles={{
                bg: "purple",
                color: "pink",
              }}
            >
              <CTAHeadingBlock heading={header.heading} />
            </ColumnBlock>
          }
          col2={
            <ColumnBlock
              styles={{
                bg: "pink",
                color: "darkPurple",
              }}
            >
              <CTAHeadingBlock
                heading={header.subheading}
                body={header.subtext}
                buttonLabel={header?.ctaButton?.label}
                buttonLink={header?.ctaButton?.link}
                smallHeading
              />
            </ColumnBlock>
          }
        />
      )}
      {threeUpBlock && threeUpBlock?.columns?.length > 0 && (
        <IconThreeUp
          textBlocks={threeUpBlock.columns.map(item => item.text)}
          heading={threeUpBlock.heading}
        />
      )}
      {threeColumnTextBlock &&
        threeColumnTextBlock.map(row => (
          <Container>
            <Section>
              <CalloutTextBlocks blocks={row.columns} heading={row.heading} />
            </Section>
          </Container>
        ))}
      {carousel && carousel.length > 0 && (
        <Section>
          <TestimonialCarousel testimonials={carousel} />
        </Section>
      )}

      <div
        sx={{
          bg: "pink",
        }}
      >
        <Section>
          {data.workshops.edges && data.workshops.edges.length ? (
            <ListingGrid
              title={"Workplace Sessions"}
              isWorkshop
              items={data.workshops.edges
                .map(mapWorkshop)
                .filter(workshop => workshop.type === "workplace")}
            />
          ) : null}
          <Spacer.Medium />
          <Spacer.Medium />
          {data.workshops.edges && data.workshops.edges.length && isSchool ? (
            <ListingGrid
              title="School & Youth Sessions"
              isWorkshop
              items={data.workshops.edges
                .map(mapWorkshop)
                .filter(workshop => workshop.type === "schools")}
            />
          ) : null}
        </Section>
      </div>
      {carouselTwo && carouselTwo.length && (
        <Section>
          <TestimonialCarousel testimonials={carouselTwo} />
        </Section>
      )}
      {accordion && accordion?.accordionItems?.length > 0 && (
        <>
          <Spacer.Medium />
          <Accordion
            heading={accordion.heading}
            items={accordion.accordionItems}
          />
          <Spacer.Medium />
        </>
      )}
      {quickLinks && (
        <QuickLinks
          heading={quickLinks.heading}
          quickLinks={quickLinks.links}
        />
      )}
      {imageGallery &&
        Object.values(imageGallery).filter(value => value !== "").length >
          0 && <ImageGallery {...imageGallery} />}
      <CallToAction {...ctaBlock} />
    </Layout>
  )
}

export default WorkshopListing
